import { z } from 'zod';
import { CurrencyEnumSchema } from '../currency.schema';
import {
  RelationshipToOne,
  RelationshipToMany,
} from '../jsonApi/jsonApiRelationships.schema';
import { EntityTypeEnumSchema } from '../entityType.schema';
import { UserDtoAttributes, UserSchema } from '../user/user.schema';
import { VenueDtoAttributes } from '../venue/venue.schema';

const ContactType = z.enum(['individual', 'company']);
const ContactState = z.enum([
  'none',
  'to_contact',
  'in_progress',
  'closed',
  'partner',
  'postponed',
]);
const BankAccount = z
  .object({
    id: z.number().int().nullish(),
    localAccount: z.string().nullish(),
    swift: z.string().nullish(),
    iban: z.string().nullish(),
    name: z.string().nullish(),
    currency: CurrencyEnumSchema.nullish(),
  })
  .passthrough();
const ContactDtoMeta = z
  .object({
    pipedriveId: z.string().nullish(),
    pipedriveDealId: z.string().nullish(),
  })
  .partial()
  .passthrough();
const Estimates = z
  .object({
    visits: z.number().int().nullish(),
    ticketPrice: z.number().int().nullish(),
    eventCount: z.number().int().nullish(),
  })
  .partial()
  .passthrough();
const ContactComment = z
  .object({ timestamp: z.string(), text: z.string() })
  .passthrough();
const BarCodeType = z.enum(['QRCode', 'BarCode', 'Hidden']);

const ContactDtoTracking = z.object({
  sklikId: z.string().nullish(),
  facebookPixelId: z.string().nullish(),
  googleAnalyticsId: z.string().nullish(),
  googleAdsId: z.string().nullish(),
});

export const ContactDtoAttributesSchema = z.object({
  name: z.string().nullish(),
  contactType: ContactType.nullish(),
  state: ContactState.nullish(),
  nameInternal: z.string().nullish(),
  countryIso: z.string().nullish(),
  vatId: z.string().nullish(),
  vatIdSecondary: z.string().nullish(),
  companyId: z.string().nullish(),
  address: z.string().nullish(),
  city: z.string().nullish(),
  postalCode: z.string().nullish(),
  vatRate: z.number().int().nullish(),
  birthDate: z.string().nullish(),
  accounts: z.array(BankAccount).nullish(),
  webHookProduction: z.string().nullish(),
  webHookDevelopment: z.string().nullish(),
  tags: z.array(z.string()).nullish(),
  meta: ContactDtoMeta.nullish(),
  estimates: Estimates.nullish(),
  comments: z.array(ContactComment).nullish(),
  barCodeType: BarCodeType.nullish(),
  sourceUrl: z.string().nullish(),
  facebookUrl: z.string().nullish(),
  tracking: ContactDtoTracking.nullish(),
});

export const ContactDtoRelationshipsSchema = z.object({
  homeVenue: RelationshipToOne('venues'),
  parent: RelationshipToOne('contacts'),
  children: RelationshipToMany('contacts'),
  users: RelationshipToMany('users'),
  responsibleUser: RelationshipToOne('users'),
  communicationUser: RelationshipToOne('users'),
  goOutHandlingUser: RelationshipToOne('users'),
});

export const ContactDtoSchema = z.object({
  attributes: ContactDtoAttributesSchema,
  id: z.string(),
  type: z.literal(EntityTypeEnumSchema.Values.contacts),
  relationships: ContactDtoRelationshipsSchema,
});

export const ContactSchema = z
  .object({
    id: z.string(),
    type: z.literal(EntityTypeEnumSchema.Values.contacts),
  })
  .merge(ContactDtoAttributesSchema)
  .merge(
    z.object({
      // Keep for documenting purposes -> What BE really returns, its recursive and zod can't put up with it
      // homeVenue: z.lazy(() => z.union([ContactSchema, z.null()])),
      // parent: z.lazy(() => z.union([ContactSchema, z.null()])),
      // children: z.lazy(() => z.array(ContactSchema)),
      users: z.lazy(() => z.array(UserSchema)),
      responsibleUser: z.lazy(() => z.union([UserSchema, z.null()])),
      communicationUser: z.lazy(() => z.union([UserSchema, z.null()])),
      goOutHandlingUser: z.lazy(() => z.union([UserSchema, z.null()])),
    })
  )
  .partial();

export type ContactDto = z.infer<typeof ContactDtoSchema>;
export type ContactDtoAttributes = z.infer<typeof ContactDtoAttributesSchema>;
export type ContactDtoRelationships = z.infer<
  typeof ContactDtoRelationshipsSchema
>;

export type ContactRelationships = {
  homeVenue: VenueDtoAttributes;
  parent: ContactDtoAttributes;
  children: ContactDtoAttributes[];
  users: UserDtoAttributes[];
  responsibleUser: UserDtoAttributes;
  communicationUser: UserDtoAttributes;
  goOutHandlingUser: UserDtoAttributes;
};

export type Contact = {
  type: typeof EntityTypeEnumSchema.Values.contacts;
  id: string;
} & ContactDtoAttributes;
