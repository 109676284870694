import { defineStore } from 'pinia';
import { onMounted, computed, ref } from 'vue';
import { Contact, User } from 'goout-schemas';
import { PERMISSION, useAuth } from 'goout-auth';
import { useRouter } from 'vue-router';
import { useLocalStorage } from '@vueuse/core';

export const useSessionStore = defineStore('session', () => {
  const user = ref<User>({} as User);
  const contacts = ref<User['contacts']>([]);
  const selectedContact = ref<Contact>({} as Contact);
  const { logout, checkContactHasPermission } = useAuth();
  const router = useRouter();
  const userData = useLocalStorage('userData', user);

  const authenticate = async () => {
    // First check local storage for user data

    if (!userData.value && !userData.value[0]) {
      await logout();
      router.push({ name: 'Overview' });
      return;
    }

    user.value = userData.value;
    // Filter out contacts that the user does not have permission for
    contacts.value = user.value.contacts.filter((contact) =>
      checkContactHasPermission(contact.id, [PERMISSION.RightCreator])
    );

    if (contacts.value.length === 0) {
      await logout();
      router.push({ name: 'Overview' });
      return;
    }

    selectedContact.value = contacts.value[0];
  };

  const contactIds = computed(() =>
    contacts.value?.map((contact) => contact.id)
  );

  onMounted(async () => {
    await authenticate();
  });

  return { authenticate, user, contacts, contactIds, selectedContact };
});
