<script setup lang="ts">
import Error from '~/components/general/Error.vue';
import { TextInputProps } from './TextInput.vue';
import { useForm } from '~/composables/useForm';

type Props = {
  rows?: number;
} & TextInputProps;

const { schema, rows = 3 } = defineProps<Props>();

const model = defineModel<string>();
const { errors } = useForm({ model, schema });
</script>

<template>
  <label class="text-sm relative block group">
    <div
      class="transform transition-all top-0 left-0 sticky pointer-events-none w-full pt-3 bg-snow-white group-hover:bg-gray/20"
    >
      <span
        class="text-gray pl-3"
        :class="[
          model && 'has-value',
          $attrs.disabled === '' && 'text-gray/30',
        ]"
      >
        {{ label }}
      </span>
    </div>
    <textarea
      v-model="model"
      :required
      class="bg-snow-white outline-none text-dark w-full px-4 pt-3 pb-4 transition-all focus:shadow-border focus:shadow-blue focus:bg-gray-50 hover:bg-gray/20 disabled:bg-snow-white"
      :class="{ 'shadow-border !shadow-red': errors.length > 0 }"
      :aria-label="label"
      :rows
    />

    <Error v-if="errors" :errors />
  </label>
</template>
