import { EntitiesIncludesMapperType } from 'goout-schemas';
import jsonApiClient, { GetArgs, ReturnTypeForEntity } from './JsonApiClient';
import { Ref, ref } from 'vue';

export interface UseFetchReturn<T> {
  /**
   * Any fetch errors that may have occurred
   */
  error: Ref<any>;

  /**
   * The fetch response body on success, may either be JSON or text
   */
  data: Ref<T>;

  /**
   * Indicates if the request is currently being fetched.
   */
  isFetching: Readonly<Ref<boolean>>;

  /**
   * Indicates if the fetch request has finished
   */
  isFinished: Readonly<Ref<boolean>>;

  /**
   * Fetches the data again
   * When using execute, set immediate to false
   */
  execute: (query?: string, throwOnFailed?: boolean) => Promise<any>;

  /**
   * Aborts the current fetch request
   */
  abort: () => void;

  /**
   * Indicates if the fetch request has been aborted
   */
  isAborted: Ref<boolean>;
}

type UseJsonApiClientArgs<T extends keyof EntitiesIncludesMapperType> = {
  immediate?: boolean;
  query?: string;
} & GetArgs<T> &
  Record<string, any>;

/**
 * To avoid try catch blocks, we can use this hook
 * The only thing it does is fetch the data and return the response in a useFetch like manner
 * Default baseUrl is /services/entities/v2 + entity
 * e.g const { data, isFetching } = useJsonApiClient({
    entity: "schedules",
    include: ["events", "venues", "images"],
    limit: 4,
  }) 
 * @param entity 
 * @returns UseFetchReturn<ReturnTypeForEntity<T>[]>
 */
export const useJsonApiClient = <T extends keyof EntitiesIncludesMapperType>({
  entity,
  language,
  include,
  limit,
  donuts,
  immediate = true,
  baseUrl,
  query,
  ...args
}: UseJsonApiClientArgs<T>): UseFetchReturn<ReturnTypeForEntity<T>[]> => {
  const isFinished = ref(false);
  const error = ref<any>(null);
  const data = ref<ReturnTypeForEntity<T>[]>([]) as Ref<
    ReturnTypeForEntity<T>[]
  >;
  const isFetching = ref(false);
  const isAborted = ref(false);

  const fetchData = async (query?: string, throwOnFailed = false) => {
    if (query === '' && typeof query === 'string') return;
    try {
      // For continuous (live) requests we can cancel the previous promise and start executing the new one
      if (isFetching.value && typeof query === 'string') {
        jsonApiClient.client.cancel('cancel');
        isFetching.value = false;
      }

      isFetching.value = true;
      const result = await jsonApiClient.get({
        ...args,
        entity,
        language,
        include,
        limit,
        query,
        donuts,
        baseUrl,
      });

      if (result instanceof Error) {
        throw result;
      }
      data.value = result as ReturnTypeForEntity<T>[];
    } catch (err) {
      if (throwOnFailed) throw err;
      error.value = err;
    } finally {
      isFinished.value = true;
      isFetching.value = false;
    }
  };

  function abort() {
    isAborted.value = true;
    isFetching.value = false;
    jsonApiClient.client.cancel('cancel');
  }


  if (immediate) fetchData();

  return {
    execute: fetchData,
    isFinished,
    error,
    data,
    isFetching,
    abort,
    isAborted,
  };
};
