import { z } from 'zod';
import { dateSchema as dateFormatSchema } from '../date.schema';

const time = z.object({
  hour: z.number().min(0).max(23).or(z.literal('')),
  minute: z.number().min(0).max(59).or(z.literal('')),
});

const timeOptional = z.object({
  hour: z.number().min(0).max(23).or(z.literal('')),
  minute: z.number().min(0).max(59).or(z.literal('')),
});

const dateSchema = z.object({
  startDate: dateFormatSchema,
  endDate: dateFormatSchema.optional().or(z.literal('')),
  startTime: time,
  endTime: timeOptional,
});

export type DateSchema = z.infer<typeof dateSchema>;

export const datesSchema = z.object({
  dates: z.array(dateSchema).min(1),
});
