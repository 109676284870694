export const PERMISSION = {
  RightCompany: 'rightCompany',
  RightCompanyGoOut: 'rightCompanyGoOut',
  RightCompanyGoOutInternational: 'rightCompanyGoOutInternational',
  RightSales: 'rightSales',
  RightTransactions: 'rightTransactions',
  RightInvoices: 'rightInvoices',
  RightDesk: 'rightDesk',
  RightCheckin: 'rightCheckin',
  RightSaleSettingsBasic: 'rightSaleSettingsBasic',
  RightSaleSettingsAdvanced: 'rightSaleSettingsAdvanced',
  RightSaleSettingsMarketing: 'rightSaleSettingsMarketing',
  RightSaleSettingsLogs: 'rightSaleSettingsLogs',
  RightPaymentsPurchases: 'rightPaymentsPurchases',
  RightPaymentsReceiptUploads: 'rightPaymentsReceiptUploads',
  RightPaymentsInvoices: 'rightPaymentsInvoices',
  RightPaymentsBank: 'rightPaymentsBank',
  RightPaymentsStatistics: 'rightPaymentsStatistics',
  RightSaleCancel: 'rightSaleCancel',
  RightCreator: 'rightCreator',
  MailingAccountant: 'mailingAccountant',
  MailingSaleEnded: 'mailingSaleEnded',
  MailingSaleEndedCheckin: 'mailingSaleEndedCheckin',
  MailingSaleEndedOverview: 'mailingSaleEndedOverview',
  MailingSalesDaily: 'mailingSalesDaily',
  ReportsSalesSimple: 'reportsSalesSimple',
  ReportsSalesDeals: 'reportsSalesDeals',
  ReportsSalesDealsCommission: 'reportsSalesDealsCommission',
  ReportsSalesFull: 'reportsSalesFull',
  ReportsSalesFullCommission: 'reportsSalesFullCommission',
  ReportsSellers: 'reportsSellers',
  ReportsSalesAverage: 'reportsSalesAverage',
  ReportsSaleInvoices: 'reportsSaleInvoices',
  ReportsHallUse: 'reportsHallUse',
  ReportsBlocking: 'reportsBlocking',
  ReportsPurchasesMailing: 'reportsPurchasesMailing',
  ReportsPurchases: 'reportsPurchases',
  Active: 'active',
} as const;

export type Permission = typeof PERMISSION[keyof typeof PERMISSION];