import { useGooutFetch } from 'goout-api';
import { USER_PERMISSIONS } from './endpoints';
import { Permission } from '../utils/permissionType';
import { ForbiddenError, InternalServerError } from 'goout-utils';
import { useAuth } from '../composables/useAuth';

export type GetPermissionsResponse = {
  rightsByContact: Record<string, Permission[]>;
};

export async function useGetUserPermissions() {
  return await useGooutFetch(USER_PERMISSIONS)
    .get()
    .json<GetPermissionsResponse>();
}

/**
 * Check if user has required permissions
 * @param permissions
 * @throws ForbiddenError
 */
export async function isUserPermitted(requiredPermissions: Permission[]) {
  const { data, error } = await useGetUserPermissions();
  const { user, permissionsPerContact } = useAuth();

  if (error.value || !data?.value) {
    throw new InternalServerError();
  }
  
  permissionsPerContact.value = data.value;
  const contacts = user.value?.contacts; // We have permission per contact

  const hasRequiredPermissions = contacts?.map((contact) => {
    const availablePermissions = permissionsPerContact.value?.rightsByContact[contact.id];
    if (!availablePermissions) return;
    
    return checkPermissions({
      availablePermissions,
      requiredPermissions,
    });
    
  });
  
  // If no true value is found, throw ForbiddenError
  if (hasRequiredPermissions?.every((permission) => !permission)) {
    throw new ForbiddenError();
  }
  return true;
}

type CheckPermissionsArgs = {
  availablePermissions: Permission[]; // Permissions fetched for the contact
  requiredPermissions: Permission[];  // Permissions needed for the operation
};

/**
 * Check if user has the required permissions
 * @param availablePermissions - Permissions fetched for the contact
 * @param requiredPermissions - Permissions needed for the operation
 */
const checkPermissions = ({
  availablePermissions,
  requiredPermissions,
}: CheckPermissionsArgs) => {
  return requiredPermissions.some((permission) =>
    availablePermissions.includes(permission)
  );
};
