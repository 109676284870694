<script setup lang="ts">
import Navigation from '~/components/layout/Navigation.vue';
import EventBoxList from '~/components/events/EventBoxList.vue';
import Button from 'goout-ui-kit/button/Button.vue';
import { computed, watch, onUnmounted } from 'vue';
import { Schedule } from 'goout-schemas';
import { useJsonApiClient } from 'goout-api';
import { useDataStore } from '~/store/data.store';
import { TabEnum } from '~/store/tabs.store';
import { useSessionStore } from '~/store/session.store';
import TopbarOverview from '../layout/TopbarOverview.vue';
import { useRouter } from 'vue-router';
import { useEventBus } from '@vueuse/core';
import { useTracking } from '~/composables/useTracking';

const router = useRouter();
const dataStore = useDataStore();
const sessionStore = useSessionStore();
const bus = useEventBus('refetch-schedules');
const { trackPageView } = useTracking();
const NUMBER_OF_SCHEDULES_TO_FETCH = 30;

const contactIds = computed(() =>
  sessionStore.selectedContact.id ? [sessionStore.selectedContact.id] : []
);

const {
  data: schedulesData,
  isFetching,
  execute: fetchSchedules,
  abort: abortSchedules,
  isAborted,
} = useJsonApiClient({
  entity: 'schedules',
  baseUrl: 'entitystore/v2',
  include: ['events', 'venues', 'images'],
  contactIds,
  pageSize: NUMBER_OF_SCHEDULES_TO_FETCH,
  immediate: false,
});

const SCHEDULE_STATE = {
  published: 'published',
  scheduled: 'scheduled',
  past: 'past',
} as const;

type ScheduleState = typeof SCHEDULE_STATE[keyof typeof SCHEDULE_STATE];

const publishedSchedules = computed(() =>
  dataStore.schedules.filter(
    (schedule) => categorizeSchedule(schedule) === SCHEDULE_STATE.published
  )
);
const scheduledSchedules = computed(() =>
  dataStore.schedules.filter(
    (schedule) => categorizeSchedule(schedule) === SCHEDULE_STATE.scheduled
  )
);

const pastSchedules = computed(() =>
  dataStore.schedules.filter(
    (schedule) => categorizeSchedule(schedule) === SCHEDULE_STATE.past
  )
);

const isLoading = computed(() => isFetching.value);

/**
 * Categorize schedule into current or upcoming
 * @param schedule
 */
function categorizeSchedule(schedule: Schedule): ScheduleState {
  // Publish is in the future
  if (schedule.publishedAt && new Date(schedule.publishedAt) >= new Date())
    return SCHEDULE_STATE.scheduled;

  return new Date(schedule?.endAt).getTime() < new Date().getTime()
    ? SCHEDULE_STATE.past
    : SCHEDULE_STATE.published;
}

const targetRoute = computed(() =>
  schedulesData.value.length > 3 || !isAborted.value
    ? {
        name: 'Wizard',
        params: { step: TabEnum.General },
      }
    : { name: 'Intro' }
);

// When schedules are fetched, set them to the store, this is triggered on refetch too
watch(schedulesData, () => {
  if (!contactIds.value) return;
  dataStore.setSchedules(schedulesData.value);
  if (schedulesData.value.length === 0 && contactIds.value?.length > 0) {
    router.push({ name: 'Intro' });
  }
});

// Refetch on contact change, one user can have multiple contacts -> Milan from Ameba
watch(
  contactIds,
  async () => {
    if (!contactIds.value.length) return;
    await fetchSchedules();
    trackPageView({
      to: router.currentRoute.value,
    });
  },
  { immediate: true }
);

// Bus used to refetch schedules
bus.on(async () => {
  await fetchSchedules();
});

// Clear bus listener on unmount to prevent memory leaks
onUnmounted(() => {
  bus.off(async () => await fetchSchedules());
  abortSchedules();
});
</script>

<template>
  <TopbarOverview />
  <div class="flex flex-row">
    <Navigation class="fixed" />
    <main class="lg:ml-84 mr-12 mx-6 my-12 lg:w-10/12 mx-auto pt-20 pl-6 pr-16">
      <section class="flex w-full justify-between">
        <h1 class="font-semibold text-3xl">
          {{ $t('layout.mainPage.myEvents') }}
        </h1>
        <router-link :to="targetRoute">
          <Button
            v-tracking="{
              category: 'add_new_event',
              label: 'Add new event',
            }"
            variant="primary"
            round
            class="!px-10"
          >
            {{ $t('layout.mainPage.addNewEventButton') }}
          </Button>
        </router-link>
      </section>
      <article class="flex flex-col gap-y-15 mt-15">
        <EventBoxList
          :schedules="publishedSchedules"
          :title="$t('event.list.title.published')"
          :is-loading
        />
        <EventBoxList
          :schedules="scheduledSchedules"
          :title="$t('event.list.title.scheduled')"
          :is-loading
        />
        <EventBoxList
          :schedules="pastSchedules"
          :title="$t('event.list.title.past')"
          :is-loading
        />
      </article>
    </main>
  </div>
</template>
