<script lang="ts" setup>
import {
  EventCategory,
  eventCategories,
  EventCategoriesWithTags,
} from 'goout-data';
import { useFormStore } from '~/store/form.store';
import CategoryOption from './CategoryOption.vue';

const { category } = useFormStore();

function getCategoryNumber(clickedCategory: EventCategory) {
  return category.data.selectedCategories.indexOf(clickedCategory) + 1;
}

function handleClick(clickedCategory: EventCategory) {
  const { selectedCategories, primaryCategory, tags } = category.data;
  const isSelected = selectedCategories.includes(clickedCategory);

  // Update selectedCategories based on whether clickedCategory is selected
  category.data.selectedCategories = isSelected
    ? selectedCategories.filter((c) => c !== clickedCategory)
    : [...selectedCategories, clickedCategory];

  // Get the first category or set null if no categories are selected
  const firstCategory = category.data.selectedCategories[0] || null;

  if (isSelected) {
    // If the clicked category is the primary, reset primaryCategory
    if (primaryCategory === clickedCategory) {
      category.data.primaryCategory = firstCategory;
    }
    // Remove all tags associated with the deselected category
    const tagsToRemove = EventCategoriesWithTags[clickedCategory].tags.flat();
    category.data.tags = tags.filter((tag) => !tagsToRemove.includes(tag));
  } else {
    // Set the primary category to the first selected category when adding a new one
    category.data.primaryCategory = firstCategory;
  }
}
</script>

<template>
  <div class="grid grid-cols-2 gap-3">
    <div v-for="category in eventCategories" :key="category">
      <CategoryOption
        :category
        :index="getCategoryNumber(category)"
        @click="handleClick(category)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
