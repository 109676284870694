import { Ref, ref, watch, computed } from 'vue';
import { z } from 'zod';
import { useDebounceFn } from '@vueuse/core';

export type FormSchemaArgs<T> = {
  schema: z.ZodSchema<T>;
  model: Ref<T>;
  immediate?: boolean;
  debounceTime?: number;
};

export interface SubmitResponse {
  error?: boolean | z.ZodIssue[];
  success?: boolean;
}

export const DEBOUNCE_TIME = 0;

export function useForm<T>({
  model,
  schema,
  immediate = false,
  debounceTime = DEBOUNCE_TIME,
}: Partial<FormSchemaArgs<T>>) {
  const errors = ref<z.ZodIssue[]>([]);
  const isLoading = ref(false);
  const result = ref();
  const debounceTimeComputed = computed(() => debounceTime);
  /**
   * Use this function to submit a form with validation
   * @param schema
   * @param onSubmit
   */
  const submit = <T>(
    schema: z.ZodSchema<T>,
    onSubmit: (values: T) => Promise<Partial<SubmitResponse>>
  ) => {
    return {
      onSubmit: async (values: T) => {
        let response: Partial<SubmitResponse>;
        isLoading.value = true;

        const newValues = schema.safeParse(values);

        if (!newValues.success) {
          errors.value = newValues.error.errors;
          response = {
            error: errors.value,
          };
          // Exit asap when input data are invalid
          return response;
        }
        // If the data is valid, we can submit the form
        try {
          response = await onSubmit(newValues.data);
          return response;
        } catch (error) {
          errors.value = error;
        } finally {
          isLoading.value = false;
        }
      },
    };
  };

  /**
   * Use this function to validate a form
   * @param schema
   * @param data
   * @returns boolean
   */
  const validate = (data: T) => {
    if (!schema) {
      throw new Error('No schema provided to validate the form.');
    }

    const validation = schema.safeParse(data);
    if (!validation.success) {
      errors.value = validation.error.errors;
      return false;
    }
    errors.value = [];
    result.value = validation.data;
    return true;
  };

  const debouncedValidate = useDebounceFn(validate, debounceTimeComputed.value);

  if (model && immediate) {
    // Automatically set up a watcher on the model
    watch(
      model,
      (newValue) => {
        debouncedValidate(newValue);
      },
      { deep: true, immediate: !!immediate }
    );
  }
  return { submit, validate, errors, result, isLoading };
}

/**
 * Abstract away type of codes since we do not have any specific Error codes
 * @param statusCode
 * @returns
 */
export function sendResponse(statusCode: Ref<number | null>) {
  return {
    error: statusCode.value ? statusCode.value >= 400 : true,
    success: statusCode.value === 204,
  };
}
