import { z } from 'zod';
import { StateEnumSchema } from '../state.schema';
import { LanguageEnumSchema } from '../language.schema';
import { RelationshipToMany } from '../jsonApi/jsonApiRelationships.schema';
import { EntityTypeEnumSchema } from '../entityType.schema';
import { Contact, ContactSchema } from '../contact/contact.schema';
import { Prettify } from '../jsonApi/utils/helpers';

const UserCounts = z.object({
  followers: z.number().int(),
  following: z.number().int(),
  performers: z.number().int(),
  venues: z.number().int(),
  events: z.number().int(),
  eventsFuture: z.number().int(),
});

const UserRoleEnum = z.enum(['USER', 'ORGANIZER', 'ADMIN', 'SELLER']);

const MetaAttributes = z.object({ pipedriveId: z.string().nullish() });

export const UserDtoAttributesSchema = z
  .object({
    firstName: z.string().nullish().nullish(),
    lastName: z.string().nullish().nullish(),
    email: z.string().nullish().nullish(),
    phone: z.string().nullish().nullish(),
    isPublic: z.boolean(),
    image: z.string().nullish().nullish(),
    bio: z.string().nullish().nullish(),
    isAmbassador: z.boolean(),
    termsAgreedAt: z.string().nullish().nullish(),
    marketingAgreedAt: z.string().nullish().nullish(),
    updatedAt: z.string().nullish().nullish(),
    counts: UserCounts.nullish(),
    calendarUrl: z.string().nullish().nullish(),
    role: UserRoleEnum.nullish(),
    loginAt: z.string().nullish().nullish(),
    meta: MetaAttributes.nullish(),
    state: StateEnumSchema.nullish(),
    language: z.string().toLowerCase().pipe(LanguageEnumSchema.nullish()),
    hasGuestlists: z.boolean().nullish(),
    siteUrl: z.string().nullish().nullish(),
  })
  .partial();

export const UserDtoRelationshipsSchema = z.object({
  contacts: RelationshipToMany('contacts'),
  guestlists: z.any(),
}); //TODO Guestlist not needed now

export const UserDtoSchema = z.object({
  attributes: UserDtoAttributesSchema,
  id: z.string().nullish().nullish(),
  type: z.literal(EntityTypeEnumSchema.Values.users),
  relationships: UserDtoRelationshipsSchema,
  links: z.object({
    self: z.string().nullish().nullish(),
  }),
});

export const UserSchema = z
  .object({
    id: z.string(),
    type: z.literal(EntityTypeEnumSchema.Values.users),
  })
  .merge(UserDtoAttributesSchema)
  .merge(
    z.object({
      contacts: z.lazy(() => z.array(ContactSchema).nullish())

    })
  ) as z.ZodSchema<User>;

export type UserDto = z.infer<typeof UserDtoSchema>;
export type UserDtoAttributes = z.infer<typeof UserDtoAttributesSchema>;
export type UserDtoRelationships = {
  contacts: Contact[];
};

export type User = Prettify<{
  type: typeof EntityTypeEnumSchema.Values.users;
  id: string;
} & UserDtoAttributes &
  UserDtoRelationships>;
