<script setup lang="ts">
import type { Tab } from './Wizard.vue';
import { computed } from 'vue';

interface Props {
  tab: Tab;
  isCurrent: boolean;
  isVisited: boolean;
  isValid: boolean;
  isDirty: boolean;
}
const props = defineProps<Props>();

const isTabValid = computed(() => props.isDirty ? props.isValid : true);
</script>

<template>
  <li
    class="h-10 box-border relative flex-1 mr-1 last:mr-0"
    role="tab"
    :aria-controls="tab.id"
    :aria-disabled="!isVisited" 
    :aria-selected="isCurrent"
    tabindex="0" 
    :class="{
      'tab-visited tab-hover cursor-pointer': isVisited,
      'tab-current': isCurrent,
      'tab-invalid': !isTabValid,
    }"
  >
    <a v-if="isVisited" class="flex h-full">
      <slot name="title">
        <span class="title text-left text-sm text-white h-full pl-4 bg-blue w-full flex items-center 
          h-0 overflow-hidden absolute bottom-0 transition-all duration-300 ease select-none"
          :class="{ 'bg-red': !isTabValid }">
          {{ tab.title }}
        </span>
      </slot>
    </a>
    <span v-else class="bottom-0 left-0 h-0.75 bg-snow-white flex absolute w-full"></span>
  </li>
</template>


<style lang="scss" scoped>
li {
  list-style-type: none;
}

.tab-visited:after {
  @apply left-0 h-0.75 bg-blue w-full absolute bottom-0 transition-all duration-300 ease;
  content: '';
}

.tab-current:after {
  @apply h-1.5 transition-all duration-300 ease;
  content: '';
}

.tab-invalid:after {
  @apply bg-red;
}

.tab-hover:hover {
  .title {
    @apply h-10;
  }
}
</style>
