export const PASSWORD_LOGIN = '/services/user/v3/password-login';
export const LOGOUT = '/services/user/v2/logout';
export const FORGOTTEN_PASSWORD = '/services/user/v1/request-lost-password';

export const OAUTH_CONNECT = '/services/user/v1/oauth-connect'; // not ready to use - saves http cookies only on SignUp, not on SignIn
export const OAUTH_LOGIN_GOOGLE = '/services/user/oauth/google/v1/connect';
export const OAUTH_LOGIN_FB = '/services/user/oauth/facebook/v1/connect';

export const USER_AUTH_STATE = '/services/user/v2/users';
export const USER_TRACKING = '/services/user/v1/tracking';

export const TOKEN_REFRESH = '/services/user/v3/refresh-tokens';

export const USERS = '/services/user/v2/users';

export const USER_PERMISSIONS = '/services/user/account/v1/rights';