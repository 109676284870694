<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { ModalTypeEnum } from '../utils/modalTypes';
import ForgottenPassword from './ForgottenPassword.vue';
import SignUp from './SignUp.vue';
import SignIn from './SignIn.vue';
import { useModalStore } from '../stores/modal.store';
import AuthModalLayout from './AuthModalLayout.vue';
import { logout } from '../api/logout';
import SuccessModal from './SuccessModal.vue';
import Icon from 'goout-icons/Icon.vue';
import { CreateAuthModalOptions } from '../index';
import { onClickOutside } from '@vueuse/core';
import { useAuth } from '../composables/useAuth';
import { handleRedirectBackFromOAuth } from '../utils/handleRedirectBackFromOAuth';
import { getUserData } from '../api/getUserData';
import { useToast } from 'goout-ui-kit/composables/useToast';
import { ForbiddenError, InternalServerError } from 'goout-utils';
import { isUserPermitted, useGetUserPermissions } from '../api/useGetUserPermissions';

const props = defineProps<CreateAuthModalOptions>();

const { currentModal, isOpen, isRemovable } = useModalStore();
const {
  isAdminOnly,
  isFetching,
  isLoggedIn,
  isFetchingGoogle,
  isFetchingFacebook,
  user
} = useAuth();
const { showToast } = useToast();

const modalTypeMap = {
  [ModalTypeEnum.signIn]: SignIn,
  [ModalTypeEnum.signUp]: SignUp,
  [ModalTypeEnum.forgottenPassword]: ForgottenPassword,
  [ModalTypeEnum.signUpSuccess]: SuccessModal,
  [ModalTypeEnum.forgottenPasswordSuccess]: SuccessModal,
};

onMounted(async () => {
  isRemovable.value = props.removable;
  isAdminOnly.value = props.isAdminOnly;
  isFetching.value = true;

  try {
    // Check if the user is redirected from OAuth, facebook, google, etc.
    if (window.location.search.includes('oauth=true')) {
      const result = await handleRedirectBackFromOAuth(props.isAdminOnly);
      const hasRequiredPermissions = await isUserPermitted(props.permissions);
      isLoggedIn.value = !!result && !!hasRequiredPermissions;
      isFetching.value = false;
      return;
    }

    // Try get userData and if 401, then user is not logged in, otherwise, user is logged in
    const result = await getUserData({
      isAdminOnly: props.isAdminOnly,
    });

    const hasRequiredPermissions = await isUserPermitted(props.permissions);
    isLoggedIn.value = !!result && !!hasRequiredPermissions;
  } catch (error) {
    console.log(error);
    if (error instanceof ForbiddenError) {
      showToast({
        heading: 'loginModule.error.forgottenPassword',
        message: 'loginModule.error.insufficient.permissions',
        position: 'top-right',
      });
    } else if (error instanceof InternalServerError) {
      showToast({
        heading: 'loginModule.error.serverError',
        message: 'loginModule.error.try.again',
        position: 'top-right',
      });
    }
    await logout(false);
  } finally {
    isFetching.value = false;
    isFetchingGoogle.value = false;
    isFetchingFacebook.value = false;
  }
});

const showModal = computed(() =>
  isOpen.value ? !isLoggedIn.value && !isFetching.value : false
);
const isSuccess = computed(() => currentModal.value.includes('Success'));
const isError = computed(() => currentModal.value.includes('Error'));
const isClosable = computed(
  () => isSuccess.value || isError.value || isRemovable.value
);

const modal = ref<HTMLElement | null>(null);
onClickOutside(modal, () => {
  if (isRemovable.value) {
    isOpen.value = false;
  }
});
</script>

<template>
  <dialog v-if="showModal" ref="modal" class="modal">
    <AuthModalLayout
      :title="`auth.modal.title.${currentModal}`"
      :description="`auth.modal.description.${currentModal}`"
      :close-icon="isClosable"
    >
      <template v-slot:icon>
        <Icon v-if="isSuccess" name="Check" class="text-blue" />
        <Icon v-else-if="isError" name="Error" class="text-red" />
      </template>
      <component :key="currentModal" :is="modalTypeMap[currentModal]" />
    </AuthModalLayout>
  </dialog>
  <div v-if="showModal" class="backdrop" />
</template>

<style scoped>
.backdrop {
  position: fixed;
  inset: 0;
  background: rgba(107, 107, 107, 0.88);
  z-index: 20;
  height: 100vh;
}

.modal {
  @apply flex flex-col rounded-[24px] max-w-[579px] w-full bg-white shadow-lg z-25 m-auto fixed inset-0;
}
</style>
