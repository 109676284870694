<script setup lang="ts">
import Icon from 'goout-icons/Icon.vue';
import Tooltip from 'goout-ui-kit/tooltip/Tooltip.vue';
import EventBoxActions from './EventBoxActions.vue';
import { getDateTimeFormat } from '~/utils';
import { computed } from 'vue';
import { getCurrentLocale } from '~/i18n';
import { Event, Schedule } from 'goout-schemas';

interface Props {
  id: Event['id'];
  locales: Event['locales'];
  venue: Schedule['venue'];
  date: Schedule['startAt'];
  scheduledDate: Schedule['publishedAt'];
  eventImageUrl?: Event['images'][0]['url'];
  compact?: boolean;
}

const {
  eventImageUrl = 'https://via.placeholder.com/100',
  date,
  scheduledDate,
  locales,
  venue,
} = defineProps<Props>();
const name = computed(() => locales?.[getCurrentLocale()]?.name);
const venueComputed = computed(
  () => venue.locales?.[getCurrentLocale()]?.name || venue.address
);

const openVenueNewTab = () => {
  const siteUrl = venue.locales?.[getCurrentLocale()]?.siteUrl ?? '';

  siteUrl
    ? window.open(siteUrl, '_blank')
    : console.warn('No valid venue site URL found for the current locale');
};
</script>

<template>
  <tr>
    <td class="object-cover h-[120px] w-min-[168px] pr-6">
      <img
        v-if="eventImageUrl"
        :src="eventImageUrl"
        alt="event image"
        class="w-full h-full object-cover"
      />
    </td>
    <td>
      <p class="text-sm font-medium text-dark text-wrap">
        {{ name }}
      </p>
    </td>
    <td class="hidden md:table-cell">
      <Tooltip
        :text="$t('event.startAt')"
        position="top"
        rounded
        class="inline-block"
      >
        <span class="cursor-pointer text-gray-dark">{{
          getDateTimeFormat(date.toString())
        }}</span>
      </Tooltip>
    </td>

    <td
      class="text-gray-dark font-medium"
      :class="{
        'cursor-pointer': venue.locales?.[getCurrentLocale()]?.siteUrl,
      }"
      @click="openVenueNewTab"
    >
      {{ venueComputed }}
    </td>

    <td class="hidden 2xl:table-cell">
      <div
        class="flex items-center text-blue"
        :class="{ 'opacity-0': !scheduledDate }"
      >
        <div>
          <Icon
            name="Clock"
            class="mt-[2px] text-blue/40 mr-2 block w-full h-full"
          />
        </div>
        <p class="font-medium">
          <span v-if="scheduledDate">
            {{ getDateTimeFormat(scheduledDate.toString()) }}
          </span>
        </p>
      </div>
    </td>

    <td>
      <EventBoxActions :id class="flex items-center justify-end" />
    </td>
  </tr>
</template>

<style scoped lang="scss">
td {
  @apply text-sm text-wrap pt-4 text-left align-middle leading-4;
}

td:nth-child(2) {
  @apply pl-6;
}
td:not(:first-child) {
  @apply border-b border-snow-white pr-10;
}
p {
  @apply text-sm font-medium;
}
</style>
