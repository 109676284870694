<script setup lang="ts">
import { z } from 'zod';
import { useForm } from '~/composables/useForm';
import Error from '~/components/general/Error.vue';
import { computed } from 'vue';

export interface TextInputProps {
  label: string;
  schema: z.ZodSchema<unknown> | z.ZodOptional<any>;
  required?: boolean;
  errors?: z.ZodIssue[];
  disabled?: boolean;
  validateImmediately?: boolean;
}

const {
  schema,
  required = false,
  disabled = false,
  errors: errorProps,
  validateImmediately = false,
} = defineProps<TextInputProps>();

const model = defineModel<string | string[]>({
  get: (value) => {
    if (Array.isArray(value)) return value.join(', ');
    return value;
  },
});
const { errors } = useForm({
  model,
  schema,
  immediate: validateImmediately || (!disabled && !errorProps),
});

const errorsComputed = computed(() => errorProps || errors.value);

const requiredComputed = computed(
  () => required || !(schema instanceof z.ZodOptional)
);
</script>

<template>
  <div class="relative h-17">
    <label class="text-sm relative block h-full">
      <input
        v-model="model"
        class="bg-snow-white outline-none bg-opacity-100 text-dark w-full pl-4 pr-16 pt-8 pb-4 transition-all focus:shadow-border focus:shadow-blue focus:bg-gray-50 hover:bg-gray/20 disabled:bg-snow-white"
        :class="{
          'shadow-border !shadow-red !bg-red !bg-opacity-20 !hover:bg-opacity-10':
            errorsComputed.length > 0,
          '!text-gray-lighter !bg-white !border-snow-white border-1': disabled,
        }"
        :aria-label="label"
        :required="requiredComputed"
        :disabled
      />
      <slot name="errors">
        <Error
          v-if="errorsComputed"
          :errors="errorsComputed"
          class="text-right"
        />
      </slot>
      <span
        class="text-gray transform transition-all top-1/2 -translate-y-1/2 left-4 absolute pointer-events-none"
        :class="{ 'has-value': model, '!text-red': errorsComputed.length > 0 }"
      >
        {{ label }}
      </span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.has-value {
  transform: translateY(-110%);
  font-size: 11px;
}

label:focus-within > .text-gray {
  transform: translateY(-110%);
  font-size: 11px;
}
</style>
