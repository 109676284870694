import { ref } from 'vue';
import { User } from 'goout-schemas';
import { logout } from '../api/logout';
import { GetPermissionsResponse } from '../api/useGetUserPermissions';
import { Permission } from '../utils/permissionType';

const user = ref<User | null>(null);

/**
 * User is logged in only if user is not null and required permissions are met ADMIN, ORGANISER, etc.
 */
const isLoggedIn = ref(false);

/**
 * If true the user is not yet logged in and we are fetching the user data
 * This is useful for showing loading spinners
 * */
const isFetching = ref(false);

/**
 * List of permissions that the user has
 */
const permissionsPerContact = ref<GetPermissionsResponse>({} as GetPermissionsResponse);

/**
 * Is user an admin
 */
const isAdminOnly = ref(false);

const isFetchingGoogle = ref(false);
const isFetchingFacebook = ref(false);
export const useAuth = () => {
  
  function checkContactHasPermission(contactId: string, permissions: Permission[]) {
    const contactPermissions = permissionsPerContact.value.rightsByContact[contactId];
    if (!contactPermissions) return false;
    return permissions.some((permission) => contactPermissions.includes(permission));
  }

  return {
    user,
    permissionsPerContact,
    isLoggedIn,
    isFetching,
    isFetchingGoogle,
    isFetchingFacebook,
    isAdminOnly,
    checkContactHasPermission,
    logout,
  };
};
