import { defineStore } from 'pinia';
import { computed, watch, shallowReactive } from 'vue';
import { useI18n } from 'vue-i18n';

import GeneralTab from '~/components/tabs/GeneralTab/GeneralTab.vue';
import CategoryTab from '~/components/tabs/CategoryTab/CategoryTab.vue';
import VenueTab from '~/components/tabs/VenueTab/VenueTab.vue';
import PerformerTab from '~/components/tabs/PerformerTab/PerformerTab.vue';
import ImageTab from '~/components/tabs/ImageTab/ImageTab.vue';
import MetadataTab from '~/components/tabs/MetadataTab/MetadataTab.vue';
import DateTab from '~/components/tabs/DateTab/DateTab.vue';
import { useRouteParams } from '@vueuse/router';
import { useFormStore } from './form.store';
import { EventCategory } from 'goout-data';
import OrganizerTab from '~/components/tabs/OrganizerTab/OrganizerTab.vue';
import PublishTab from '~/components/tabs/PublishTab/PublishTab.vue';

export const TabEnum = {
  General: 'general',
  Category: 'category',
  Venue: 'venue',
  Performer: 'performer',
  MetadataFilm: 'metadata_film',
  MetadataPlay: 'metadata_play',
  Image: 'image',
  Date: 'date',
  Organizer: 'organizer',
  Publish: 'publish',
} as const;

export const metadataEligibleTabs = [
  TabEnum.MetadataFilm,
  TabEnum.MetadataPlay,
];

export type TabEnum = typeof TabEnum[keyof typeof TabEnum];

export const useTabsStore = defineStore('tabs', () => {
  const { t } = useI18n();
  const currentTabName = useRouteParams<TabEnum>('step');
  const formStore = useFormStore();

  const tabs: Map<
    TabEnum,
    {
      order: number;
      title: string;
      component: InstanceType<any>;
      type?: EventCategory;
    }
  > = shallowReactive(
    new Map([
      [
        TabEnum.General,
        {
          order: 1,
          title: t(`tab.${TabEnum.General}`),
          component: GeneralTab,
        },
      ],
      [
        TabEnum.Category,
        {
          order: 2,
          title: t(`tab.${TabEnum.Category}`),
          component: CategoryTab,
        },
      ],
      [
        TabEnum.Venue,
        {
          order: 6,
          title: t(`tab.${TabEnum.Venue}`),
          component: VenueTab,
        },
      ],
      [
        TabEnum.Performer,
        {
          order: 7,
          title: t(`tab.${TabEnum.Performer}`),
          component: PerformerTab,
        },
      ],
      [
        TabEnum.Image,
        {
          order: 8,
          title: t(`tab.${TabEnum.Image}`),
          component: ImageTab,
        },
      ],
      [
        TabEnum.Date,
        {
          order: 9,
          title: t(`tab.${TabEnum.Date}`),
          component: DateTab,
        },
      ],
      [
        TabEnum.Organizer,
        {
          order: 10,
          title: t(`tab.${TabEnum.Organizer}`),
          component: OrganizerTab,
        },
      ],
      [
        TabEnum.Publish,
        {
          order: 11,
          title: t(`tab.${TabEnum.Publish}`),
          component: PublishTab,
        },
      ],
    ])
  );

  // Getters
  const currentComponent = computed(
    () => tabs.get(currentTabName.value)?.component
  );
  const currentTabType = computed(
    () => tabs.get(currentTabName.value)?.type || ''
  );

  const sortedTabs = computed(
    () => new Map([...tabs.entries()].sort((a, b) => a[1].order - b[1].order))
  );

  const currentTabIndex = computed(() =>
    Array.from(sortedTabs.value.keys()).indexOf(currentTabName.value)
  );

  // Actions
  function goToNextTab() {
    if (currentTabName.value === 'publish') return;
    const nextTab = Array.from(sortedTabs.value.keys())[
      currentTabIndex.value + 1
    ];
    currentTabName.value = nextTab;
  }

  function goToPreviousTab() {
    const prevTab = Array.from(sortedTabs.value.keys())[
      currentTabIndex.value - 1
    ];
    currentTabName.value = prevTab;
  }

  function getCategoryWithMetadata(category: EventCategory) {
    const tabsNormalized = [...metadataEligibleTabs].map(
      (tab) => tab.replace('metadata_', '') as EventCategory
    );
    return tabsNormalized.includes(category);
  }

  /**
   * Simplifies and clarifies the construction of metadata tab keys.
   * Assumes `TabEnum` and `EventCategory` are properly defined elsewhere.
   * @param {EventCategory} cat - The event category.
   * @returns {TabEnum} - The tab enum key for the given category's metadata.
   */
  function getMetadataTab(cat: EventCategory): TabEnum {
    return `metadata_${cat}` as TabEnum;
  }

  /**
   * Watches for changes in the primary category and updates the tabs accordingly.
   */
  watch(
    () => formStore.category.data.primaryCategory,
    (newPrimaryCategory, oldPrimaryCategory) => {
      const hasNewPrimaryCategoryMetadataTab = newPrimaryCategory
        ? getCategoryWithMetadata(newPrimaryCategory)
        : false;

      const hasOldPrimaryCategoryMetadataTab = oldPrimaryCategory
        ? getCategoryWithMetadata(oldPrimaryCategory)
        : false;

      // Remove tab that is not selected anymore as primary category.
      if (hasOldPrimaryCategoryMetadataTab && oldPrimaryCategory) {
        tabs.delete(getMetadataTab(oldPrimaryCategory));
      }

      if (!hasNewPrimaryCategoryMetadataTab || !newPrimaryCategory) return;
      // Add or update tabs for the new selected categories.
      tabs.set(getMetadataTab(newPrimaryCategory), {
        order: 3, // Start metadata tabs at tab 3. (after category tab) according to the design
        title: t(`tab.${getMetadataTab(newPrimaryCategory)}`),
        component: MetadataTab,
        type: newPrimaryCategory,
      });

      // Remove performer tab, metadata pages replaces it
      if (hasNewPrimaryCategoryMetadataTab) {
        tabs.delete(TabEnum.Performer);
      } else {
        tabs.set(TabEnum.Performer, {
          order: 7,
          title: t(`tab.${TabEnum.Performer}`),
          component: PerformerTab,
        });
      }
    },
    { immediate: true, deep: true }
  );

  return {
    tabs: sortedTabs,
    currentComponent,
    currentTab: currentTabName,
    goToNextTab,
    goToPreviousTab,
    currentTabType,
  };
});
