import z from 'zod';

export const imageSchema = z.object({
  id: z.string(),
  progress: z.number().max(100).nullish(),
  file: z.any(),
  name: z.string().nullish().describe('Jane Doe'),
  copyright: z.string().nullish().describe('© 2014 Jane Doe.'),
  origin: z.string().nullish().describe('https://bandwebsite.com'),
  url: z.string().nullish().describe('https://goout.net/i/089/897920-383.jpg'),
  controller: z.any().nullish().describe('AbortController'),
});

export const imagesSchema = z.object({
  images: z.array(imageSchema).min(1).max(5),
});

export type Image = z.infer<typeof imageSchema>;
export type Images = z.infer<typeof imagesSchema>;
