// TODO handling refreshToken

import { useJsonApiClient } from 'goout-api';
import { ForbiddenError, UnauthorizedError } from 'goout-utils';
import { UserSchema } from 'goout-schemas';
import { useAuth } from '../composables/useAuth';

/**
 * After user is authenticated, get user data and store it in local storage
 * @param isAdminOnly - If true, only admin users will be allowed
 * @throws UnauthorizedError, ForbiddenError
 * @returns boolean
 */
export async function getUserData({
  isAdminOnly = false,
}: { isAdminOnly?: boolean } = {}) {
  const { user: userAuth } = useAuth();
  const { data, error, execute } = useJsonApiClient({
    entity: 'users',
    include: ['contacts'],
    me: true,
    immediate: false,
    baseUrl: 'user/v2',
  });

  await execute();

  // Error check, Including Unauthorized error
  if (error.value) {
    throw new UnauthorizedError();
  }

  // JsonAPI response, we only need the first user
  const userData = data.value[0];

  // Check if user is admin
  if (isAdminOnly && userData.role !== 'ADMIN') {
    throw new ForbiddenError();
  }

  // Only set user data to local storage if credentials are valid and permissions are met
  const user = UserSchema.parse(userData);
  userAuth.value = userData;
  localStorage.setItem('userData', JSON.stringify(user));
  return true;
}
