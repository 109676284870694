import { parse } from 'date-fns';
import { ScheduleDto, EntityTypeEnumSchema, EntityType } from 'goout-schemas';
import { ENDPOINT } from '~/definitions/constants/endpoints';
import { useFormStore } from '~/store/form.store';
import { DateSchema } from '~/definitions/schemas/date/date.schema';
import { useGooutFetch } from 'goout-api';
import { SourceSchema } from 'goout-schemas/source.schema';

type ScheduleAttributesWithoutId = Omit<ScheduleDto['attributes'], 'id'>;

type NewSchedule = {
  type: EntityType;
  links: {
    self: string;
  };
  attributes: ScheduleAttributesWithoutId;
  relationships: ScheduleDto['relationships'];
};

export function usePublishSchedule() {
  const { category, venue, date, organizer } = useFormStore();

  async function perform(eventId: string) {
    // Prepare schedules to iterate over later
    const schedulesData: NewSchedule[] = date.data.dates
      // Filter out incomplete dates
      .filter((date) => date.startDate !== '')
      // Map to ScheduleDtox
      .map((date) => {
        return {
          type: EntityTypeEnumSchema.enum.schedules,
          links: {
            self: '',
          },
          attributes: {
            state: 'unapproved',
            startAt: parseDate(date.startDate, date.startTime),
            endAt: parseDate(
              date.endDate || date.startDate,
              date.endTime || { hour: 23, minute: 55 }
            ),
            hasTime: date.startTime.hour !== '' && date.startTime.minute !== '',
            isPermanent: false,
            tags: category.data.tags,
            sourceUrls: [],
            source: SourceSchema.enum.creator,
            updatedAt: new Date().toISOString(),
            counts: {
              followers: 0,
              groupedSchedules: 0,
              groupedSchedulesInVenues: 0,
            },
            publishedAt: '',
            locales: {},
          },
          relationships: {
            contacts: {
              data: organizer.data.selectedItems.map((org) => {
                return {
                  id: org.id,
                  type: EntityTypeEnumSchema.enum.contacts,
                };
              }),
            },
            event: {
              data: {
                id: eventId,
                type: EntityTypeEnumSchema.enum.events,
              },
            },
            venue: {
              data: {
                id: venue.data.selectedItem.id,
                type: EntityTypeEnumSchema.enum.venues,
              },
            },
            parent: null,
            duplicateSchedules: {
              data: [],
            },
            parentInnerSchedules: {
              data: [],
            },
            innerSchedules: {
              data: [],
            },
          },
        };
      });

    const publish = schedulesData.map((schedule) => {
      const { execute } = useGooutFetch<ScheduleDto>(ENDPOINT.SCHEDULES, {
        immediate: false,
      })
        .post(schedule)
        .json();
      return execute;
    });
    // Execute the functions to get the promises
    const publishPromises = publish.map((execute) => execute());

    return await Promise.allSettled(publishPromises);
  }

  /* Convert a string to valid date */
  function parseDate(
    date: DateSchema['startDate'],
    time: DateSchema['startTime']
  ): Date {
    // Set endTime automatically

    if (time.hour === '' && time.minute === '') {
      return parse(date, 'yyyy-MM-dd', new Date());
    }

    const paddedTime = padTime(time);

    return parse(
      `${date} ${paddedTime.hour}:${paddedTime.minute}`,
      'yyyy-MM-dd HH:mm',
      new Date()
    );
  }

  function padTime(time: DateSchema['startTime']) {
    return {
      hour: String(time.hour).padStart(2, '0'),
      minute: String(time.minute).padStart(2, '0'),
    };
  }

  return { perform };
}
